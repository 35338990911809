import React from "react";

const TermsOfService = () => {
  return (
    <div className="terms-of-service-container">
      <h1>Terms of Service</h1>

      <section>
        <p>
          THESE TERMS OF SERVICE GOVERN YOUR ACCESS TO AND USE OF THE SERVICES
          PROVIDED BY APPLIMATCH ("Company"). BY (A) PURCHASING ACCESS TO THE
          SERVICE THROUGH AN ONLINE ORDERING PROCESS THAT REFERENCES THIS
          AGREEMENT, (B) SIGNING UP FOR A FREE OR PAID ACCESS PLAN FOR THE
          SERVICE VIA A PLATFORM THAT REFERENCES THIS AGREEMENT, OR (C) CLICKING
          A BOX INDICATING ACCEPTANCE, YOU AGREE TO BE BOUND BY THE TERMS OF
          THIS AGREEMENT. IF YOU DO NOT AGREE TO THESE TERMS, DO NOT USE THE
          SERVICES.
        </p>
      </section>

      <section>
        <h2>1. The Service</h2>

        <h3>1.1 Service Description</h3>
        <p>
          AppliMatch offers a cloud-based service designed to help users
          optimize their job application processes. AppliMatch enables users to
          efficiently apply to multiple job postings, providing tools for
          generating tailored resumes and cover letters for each application.
          Users are solely responsible for the content they upload or generate
          using the Service.
        </p>

        <h3>1.2 Subscription</h3>
        <p>
          Access to the Service is provided through a credit-based subscription
          plan selected during the ordering process.
        </p>
        <ul>
          <li>
            Each job application requires 2 credits: 1 credit for generating a
            tailored resume and 1 credit for generating a customized cover
            letter.
          </li>
          <li>
            Credits are valid for a specific period as outlined in the Order and
            unused credits will expire if not used within that period.
          </li>
          <li>
            Subscriptions automatically renew unless canceled prior to the
            renewal date. Users may use the Service solely for personal job
            application purposes and in accordance with this Agreement.
          </li>
        </ul>

        <h3>1.3 Ownership</h3>
        <p>
          AppliMatch retains all rights, title, and interest in the Service,
          including software, templates, scripts, and documentation provided
          ("AppliMatch Materials"). No rights are granted except those expressly
          outlined in this Agreement.
        </p>

        <h3>1.4 Permissions and Responsibilities</h3>
        <p>You are responsible for:</p>
        <ul>
          <li>Managing user access and permissions within your account.</li>
          <li>
            Providing accurate and truthful information. Misrepresentation,
            including but not limited to creating false work histories or
            uploading misleading information, is considered misuse of the
            Service and may result in suspension or termination of your account.
          </li>
        </ul>
        <p>Examples of misuse include, but are not limited to:</p>
        <ul>
          <li>Submitting fake or inaccurate job histories.</li>
          <li>
            Attempting to automate, resell, or share access to the Service.
          </li>
          <li>Uploading illegal or malicious content.</li>
        </ul>
      </section>

      <section>
        <h2>2. Restrictions</h2>
        <h3>2.1 Use Restrictions</h3>
        <p>You may not:</p>
        <ul>
          <li>
            Modify, create derivative works of, or reverse-engineer the Service;
          </li>
          <li>Sublicense, resell, or share access to the Service;</li>
          <li>
            Use the Service for illegal purposes or infringe on third-party
            rights;
          </li>
          <li>
            Attempt to disrupt the Service, access unauthorized data, or misuse
            the system.
          </li>
        </ul>
      </section>

      <section>
        <h2>3. Third-Party Services and Liability</h2>
        <p>
          The Service may integrate with third-party services ("Third-Party
          Services"). Your use of Third-Party Services is governed by their
          respective terms and is at your own risk. AppliMatch is not liable for
          any damages or issues arising from the use of Third-Party Services,
          including but not limited to service interruptions, errors, or
          security breaches.
        </p>
      </section>

      <section>
        <h2>4. Payment and Fees</h2>

        <h3>4.1 Fees</h3>
        <p>
          You agree to pay subscription fees as specified in the Order. All
          payments are non-refundable, except in cases where the Service is
          deemed unusable due to technical issues caused by AppliMatch, as
          solely determined by AppliMatch.
        </p>

        <h3>4.2 Payment</h3>
        <p>
          Payments will be processed via your provided payment method. You are
          responsible for maintaining accurate billing information. Failure to
          provide accurate information or timely payment may result in service
          suspension.
        </p>

        <h3>4.3 Taxes</h3>
        <p>
          You are responsible for any taxes associated with your purchase of the
          Service.
        </p>

        <h3>4.4 Failure to Pay</h3>
        <p>
          If you fail to pay any fees when due, AppliMatch may suspend or
          terminate access to the Service until overdue amounts are paid in
          full.
        </p>
      </section>

      <section>
        <h2>5. Term and Termination</h2>

        <h3>5.1 Agreement Term and Renewals</h3>
        <p>
          This Agreement begins on the start date of your Subscription and
          remains effective until terminated. Subscriptions automatically renew
          unless canceled prior to the renewal date.
        </p>

        <h3>5.2 Termination</h3>
        <p>
          Either party may terminate this Agreement for material breach, with
          written notice, provided the breach is not cured within 30 days.
          AppliMatch reserves the right to terminate accounts for misuse of the
          Service as defined under Section 1.4.
        </p>

        <h3>5.3 Effect of Termination</h3>
        <ul>
          <li>Your access to the Service will be revoked.</li>
          <li>Any remaining fees are immediately due.</li>
          <li>
            User data will be deleted within 30 days following termination. To
            request data deletion, you must submit a written request to
            AppliMatch.
          </li>
        </ul>
      </section>

      <section>
        <h2>6. Warranties and Disclaimers</h2>

        <h3>6.1 Warranties</h3>
        <p>
          You represent that your use of the Service complies with all
          applicable laws.
        </p>

        <h3>6.2 Disclaimer</h3>
        <p>
          The Service is provided "as is" without warranties of any kind. While
          AppliMatch aims to optimize your job application process, it cannot
          guarantee that using the Service will result in securing an interview
          or job. Numerous factors beyond AppliMatch’s control, such as the
          employer’s preferences, market conditions, and the content of your
          application materials, affect the outcome of your applications.
        </p>
      </section>

      <section>
        <h2>7. Limitation of Liability</h2>
        <p>
          AppliMatch’s total liability for any claim will not exceed the amount
          you paid for the Service in the 12 months preceding the claim.
          AppliMatch is not liable for indirect, incidental, or consequential
          damages.
        </p>
      </section>

      <section>
        <h2>8. Confidentiality and Data Protection</h2>

        <h3>8.1 Confidentiality</h3>
        <p>
          Confidential Information includes non-public information shared by
          either party. The receiving party agrees to protect the disclosing
          party’s Confidential Information with reasonable care and use it
          solely to fulfill obligations under this Agreement.
        </p>

        <h3>8.2 User Data</h3>
        <p>
          AppliMatch employs reasonable security measures to protect user data.
          However, you are also responsible for securing your systems and
          uploaded data. AppliMatch will not access or use User Submissions
          except as necessary to provide the Service.
        </p>
      </section>

      <section>
        <h2>9. Dispute Resolution</h2>

        <h3>9.1 Arbitration Agreement</h3>
        <p>
          Any dispute, claim, or controversy arising out of or relating to this
          Agreement or the Service shall be resolved through binding arbitration
          in accordance with the rules of the American Arbitration Association
          ("AAA").
        </p>
        <ul>
          <li>Arbitration will take place in the United States.</li>
          <li>
            You and AppliMatch waive the right to pursue claims in court or
            participate in class actions.
          </li>
          <li>
            Judgment on the arbitration award may be entered in any court having
            jurisdiction.
          </li>
        </ul>
      </section>

      <section>
        <h2>10. General Terms</h2>

        <h3>10.1 Governing Law</h3>
        <p>
          This Agreement is governed by the laws of the United States of
          America.
        </p>

        <h3>10.2 Entire Agreement</h3>
        <p>
          This Agreement constitutes the entire understanding between the
          parties and supersedes all prior agreements. Any amendments must be in
          writing and signed by both parties.
        </p>

        <h3>10.3 Assignment</h3>
        <p>
          You may not assign this Agreement without AppliMatch's prior written
          consent.
        </p>
      </section>

      <footer>
        <p>
          &copy; {new Date().getFullYear()} AppliMatch. All rights reserved.
        </p>
      </footer>
    </div>
  );
};

export default TermsOfService;
