import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom"; // Import useLocation
import { AuthContext } from "../context/AuthContext";
import useWebSocket from "../hooks/useWebSocket";
import logo from "../assets/logo.png";
import NotificationRingIcon from "./NotificationRingIcon";
import CreditButton from "./CreditButton";
import ProfileDropDownButton from "./ProfileDropDownButton";
import ThemeToggle from "../ThemeToggle";
import "../styles.css";
import { useAuth0 } from "@auth0/auth0-react";
import { CreditContext } from "../context/CreditContext"; // <-- import CreditContext

const Header = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [menuOpen, setMenuOpen] = useState(false);
  const { isAuthenticated, logout, userProfile } = useContext(AuthContext);
  const { creditBalance } = useContext(CreditContext);

  const navigate = useNavigate();
  const location = useLocation(); // Get the current route
  const wsUrl = process.env.REACT_APP_WS_URL;
  const { loginWithRedirect } = useAuth0();

  const handleLogoutClick = () => {
    logout();
  };

  /*const handleSignupClick = () => {
    // Auth0 "screen_hint=signup" OR navigate to local /signup if you truly want a form
    loginWithRedirect({
      authorizationParams: {
        screen_hint: "signup",
        redirect_uri: window.location.origin,
        audience: "https://auth.applimatch.co/api",
      },
    });
  };*/

  const handleSignupClick = () => {
    navigate("/signup");
  };

  const handleLoginClick = () => {
    loginWithRedirect({
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: "https://auth.applimatch.co/api",
      },
    });
  };

  const handleDashboardClick = () => {
    navigate("/dashboard");
  };

  useEffect(() => {}, [isAuthenticated, logout, getAccessTokenSilently]);

  // Check if on home page and authenticated
  const isHome = location.pathname === "/";
  const isLoginOrSignup =
    location.pathname === "/login" || location.pathname === "/signup";

  return (
    <header className="navbar">
      <div className="navbar-container">
        <a href="/" className="navbar-logo">
          <div className="logo-container">
            <img src={logo} alt="Logo" className="logo-image" />
            <span className="beta-tag">BETA</span>
          </div>
        </a>
        <nav className={`navbar-menu ${menuOpen ? "open" : ""}`}>
          <a href="/">Home</a>
          <a href="https://applimatch.mixa.site/">Blog</a>
          <a href="/#faq-section-id">FAQ</a>
          {isAuthenticated && <a href="/contact-us">Contact Us</a>}
        </nav>

        <div className="navbar-buttons">
          {isAuthenticated ? (
            isHome ? (
              <button
                onClick={handleDashboardClick}
                className="navbar-button dashboard-button"
              >
                Dashboard
              </button>
            ) : (
              <>
                <NotificationRingIcon unreadCount={3} />
                <CreditButton creditBalance={creditBalance} />
                <ProfileDropDownButton />
                <ThemeToggle />
              </>
            )
          ) : (
            <>
              <button
                onClick={handleLoginClick}
                className="navbar-button login-button"
              >
                Log In
              </button>
              <button
                onClick={handleSignupClick}
                className="navbar-button signup-button"
              >
                Join Now
              </button>
            </>
          )}
        </div>
        {/* Burger menu for mobile */}
        <button className="burger-menu" onClick={() => setMenuOpen(!menuOpen)}>
          <span className="burger-bar"></span>
          <span className="burger-bar"></span>
          <span className="burger-bar"></span>
        </button>
      </div>
    </header>
  );
};

export default Header;
