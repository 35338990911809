// src/App.js

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "./ThemeContext";

import Header from "./components/Header";
import Home from "./pages/Home";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Notifications from "./pages/Notifications";
import Profile from "./pages/Profile";
import { AuthProvider } from "./context/AuthContext";
import AccountSettings from "./pages/AccountSettings";
import PurchaseCredits from "./components/PurchaseCredits";
import PaymentSuccess from "./pages/PaymentSuccess";
import PaymentCancelled from "./pages/PaymentCancelled";
import ProtectedRoute from "./components/ProtectedRoute"; // Import ProtectedRoute
import PublicRoute from "./components/PublicRoute"; // Import PublicRoute
import AdminDashboard from "./pages/AdminDashboard";
import { jwtDecode } from "jwt-decode";
import { CreditProvider } from "./context/CreditContext";
import ContactUs from "./pages/ContactUs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import DPA from "./pages/DPA";
import RefundPolicy from "./pages/RefundPolicy";
import TermsOfService from "./pages/TermsOfService";
import "@fortawesome/fontawesome-free/css/all.min.css";
import AdminRoute from "./components/AdminRoute";
import VerifyEmail from "./pages/VerifyEmail";
import { Analytics } from "@vercel/analytics/react";
import { useAuth0 } from "@auth0/auth0-react";

function App() {
  return (
    <AuthProvider>
      <Router>
        <ThemeProvider>
          <CreditProvider>
            <Header />
          </CreditProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/admin"
              element={
                <AdminRoute>
                  <AdminDashboard />
                </AdminRoute>
              }
            />
            {/* Public Routes */}
            <Route
              path="/signup"
              element={
                <PublicRoute>
                  <Signup />
                </PublicRoute>
              }
            />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route
              path="/login"
              element={
                <PublicRoute>
                  <Login />
                </PublicRoute>
              }
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/dpa" element={<DPA />} />
            <Route path="/refund-policy" element={<RefundPolicy />} />
            <Route path="/terms-service" element={<TermsOfService />} />
            {/* Protected Routes */}
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/notifications"
              element={
                <ProtectedRoute>
                  <Notifications />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/account-settings"
              element={
                <ProtectedRoute>
                  <AccountSettings />
                </ProtectedRoute>
              }
            />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route
              path="/purchase-credits"
              element={
                <ProtectedRoute>
                  <PurchaseCredits />
                </ProtectedRoute>
              }
            />
            <Route
              path="/payment-success"
              element={
                <ProtectedRoute>
                  <PaymentSuccess />
                </ProtectedRoute>
              }
            />
            <Route
              path="/payment-cancelled"
              element={
                <ProtectedRoute>
                  <PaymentCancelled />
                </ProtectedRoute>
              }
            />
            {/* Add other routes as necessary */}
          </Routes>
        </ThemeProvider>
      </Router>
    </AuthProvider>
  );
}

export default App;
