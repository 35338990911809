// src/context/CreditContext.js
import React, { createContext, useState, useEffect, useContext } from "react";
import useWebSocket from "../hooks/useWebSocket";
import { AuthContext } from "./AuthContext";

export const CreditContext = createContext();

export const CreditProvider = ({ children }) => {
  const [creditBalance, setCreditBalance] = useState(0);
  const { isAuthenticated, userProfile } = useContext(AuthContext);
  const wsUrl = process.env.REACT_APP_WS_URL;

  const handleWebSocketMessage = (newBalance) => {
    setCreditBalance(newBalance);
  };

  // 1) If isAuthenticated and userProfile exist, we open a socket:
  useWebSocket(
    isAuthenticated && userProfile ? wsUrl : null,
    handleWebSocketMessage
  );

  // 2) Also initialize creditBalance from userProfile
  useEffect(() => {
    if (userProfile && typeof userProfile.credit_balance === "number") {
      setCreditBalance(userProfile.credit_balance);
    }
  }, [userProfile]);

  return (
    <CreditContext.Provider value={{ creditBalance, setCreditBalance }}>
      {children}
    </CreditContext.Provider>
  );
};
