import { Navigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { jwtDecode } from "jwt-decode";
import { useAuth0 } from "@auth0/auth0-react";

const AdminRoute = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { isAuthenticated } = useContext(AuthContext);

  const checkAdmin = (token) => {
    if (!token) return false;
    const decoded = jwtDecode(token);
    console.log("Decoded token:", decoded);
    return decoded.role === "admin"; // Verify this matches your token's role field name
  };

  const canAccess = isAuthenticated && checkAdmin();
  console.log("Access granted:", canAccess);

  return canAccess ? children : <Navigate to="/dashboard" />;
};

export default AdminRoute;
