import axios from "axios";

export const API_URL =
  process.env.REACT_APP_API_URL || "http://localhost:5000/api";

/*
export const signup = async (userData) => {
  try {
    const response = await fetch(`${API_URL}/auth/signup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    });

    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(errorMessage || "Failed to signup");
    }

    return await response.json();
  } catch (error) {
    console.error("Signup error:", error.message);
    throw error;
  }
};

export const login = async (userData) => {
  console.log("API_URL:", API_URL);
  try {
    const response = await axios.post(`${API_URL}/auth/login`, userData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data; // Assuming backend sends { token, user }
  } catch (error) {
    // Throw an error with the backend message
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error("Failed to login");
    }
  }
};

export const logout = async (token) => {
  const response = await fetch(`${API_URL}/auth/logout`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.json();
};
*/

export const requestPasswordReset = async (email) => {
  const response = await fetch(`${API_URL}/auth/request-password-reset`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email }),
  });
  return response.json();
};

export const resetPassword = async (data) => {
  const response = await fetch(`${API_URL}/auth/reset-password`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return response.json();
};

// New functions
export const getUserJobs = async (token) => {
  if (!token) {
    throw new Error("Unauthorized");
  }
  const response = await fetch(`${API_URL}/jobs`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  // Add a log to check the raw API response
  const jobsData = await response.json();
  console.log("Jobs data:", jobsData);

  //console.log("API Response for user jobs:", jobsData);

  if (!response.ok) {
    throw new Error("Failed to fetch user jobs");
  }

  return jobsData;
};

export const addJobLink = async (jobData, token) => {
  if (!token) throw new Error("Unauthorized");

  try {
    const response = await fetch(`${API_URL}/jobs`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(jobData), // Pass the job data directly
    });

    if (!response.ok) {
      const errorText = await response.text(); // Capture the error message from the response
      throw new Error(`Failed to add job link: ${errorText}`);
    }

    return response.json();
  } catch (error) {
    console.error("Error adding job link:", error);
    throw error;
  }
};

export const updateJobLink = async (jobId, jobData, token) => {
  if (!token) throw new Error("Unauthorized");
  const response = await fetch(`${API_URL}/jobs/${jobId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // Attach the token to the request
    },
    body: JSON.stringify(jobData),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || "Failed to update job link");
  }

  return response.json();
};

export const deleteJobLink = async (jobId, token) => {
  if (!token) {
    throw new Error("No token found. Please login again.");
  }
  const response = await axios.delete(`${API_URL}/jobs/${jobId}`, {
    headers: {
      Authorization: `Bearer ${token}`, // Include authorization header
    },
  });
  return response.data;
};

export const getNotifications = async (userId) => {
  const response = await fetch(`${API_URL}/notifications/${userId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.json();
};

export const markNotificationAsRead = async (userId, notificationId) => {
  const response = await fetch(`${API_URL}/notifications/${notificationId}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ userId }),
  });
  return response.json();
};

export const getUserProfile = async (token) => {
  if (!token) {
    throw new Error("No token found. Please login again.");
  }
  const response = await fetch(`${API_URL}/users/me`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    const errorText = await response.text();
    console.error(
      `Error in getUserProfile: status=${response.status} message=${errorText}`
    );
    throw new Error("Failed to fetch user profile");
  }

  return response.json();
};

export const getUserJobApplications = async (userId) => {
  const response = await fetch(`${API_URL}/job-applications/user/${userId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.json();
};

export const uploadResume = async (file, token) => {
  if (!token) {
    throw new Error("No token found. Please login again.");
  }
  const formData = new FormData();
  formData.append("resume", file);

  try {
    const response = await axios.post(
      `${API_URL}/jobs/resumes/upload`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`, // Include token in Authorization header
        },
      }
    );
    return response.data; // Return the response data, which includes resumeUrl
  } catch (error) {
    console.error("Error uploading resume:", error);
    throw new Error("Failed to upload resume");
  }
};

export const tailorResume = async (jobId, onProgress, token) => {
  if (!token) {
    throw new Error("No token found. Please login again.");
  }
  try {
    const response = await axios.post(
      `${API_URL}/jobs/resumes/tailor`,
      { jobId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        onDownloadProgress: (progressEvent) => {
          if (onProgress) {
            const total = progressEvent.total || 100;
            const progress = Math.round((progressEvent.loaded * 100) / total);
            onProgress(progress);
            //console.log("Progress: ", progress);
          }
        },
      }
    );

    return response.data; // Return tailored data
  } catch (error) {
    if (error.response && error.response.status === 400) {
      // Check if the error message matches insufficient credits
      if (
        error.response.data.message ===
        "Insufficient credits to perform this action."
      ) {
        throw new Error(
          "You do not have enough credits to tailor the resume. Please purchase more credits."
        );
      }
    }
    // Handle other errors
    throw new Error("An error occurred while tailoring the resume.");
  }
};

export const tailorCoverLetter = async (jobId, token) => {
  if (!token) {
    throw new Error("No token found. Please login again.");
  }
  try {
    const response = await axios.post(
      `${API_URL}/jobs/coverletters/tailor`,
      { jobId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      // Check if the error message matches insufficient credits
      if (
        error.response.data.message ===
        "Insufficient credits to perform this action."
      ) {
        throw new Error(
          "You do not have enough credits to tailor the cover letter. Please purchase more credits."
        );
      }
    }
    // Handle other errors
    throw new Error("An error occurred while tailoring the cover letter.");
  }
};

export const handleDownloadResume = async (jobId) => {
  try {
    const response = await fetch(`/api/resumes/${jobId}/download-link`);
    const data = await response.json();
    if (data.link) {
      window.location.href = data.link; // Redirect to the pre-signed URL to download
    } else {
      alert("Failed to generate download link.");
    }
  } catch (error) {
    console.error("Error fetching download link:", error);
    alert("Failed to generate download link.");
  }
};

export const addUserCredits = async (userId, amount, token) => {
  if (!token) {
    throw new Error("No token found. Please login again.");
  }

  const response = await fetch(`${API_URL}/users/${userId}/update-credit`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ amount }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || "Failed to update credits");
  }

  return await response.json();
};

/* Admin only */

// Fetch all users
export const getAllUsers = async (token) => {
  if (!token) {
    throw new Error("Unauthorized");
  }

  try {
    const response = await axios.get(`${API_URL}/admin/users`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching all users:", error);
    throw error;
  }
};

// Fetch all transactions
export const getAllTransactions = async (
  limit = 25,
  exclusiveStartKey = null,
  token
) => {
  if (!token) {
    throw new Error("Unauthorized");
  }

  try {
    const response = await axios.get(`${API_URL}/admin/transactions`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { limit, exclusiveStartKey }, // Pass limit and exclusiveStartKey as query params
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching transactions:", error);
    throw error;
  }
};

/**
 * Logs a new transaction
 */
export const logTransaction = async (transactionData, token) => {
  if (!token) {
    throw new Error("No token found. Please login again.");
  }
  try {
    const response = await axios.post(
      `${API_URL}/admin/transactions`,
      transactionData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error logging transaction:", error);
    throw error;
  }
};

// Update user credits
export const updateUserCredits = async (userId, amount, token) => {
  if (!token) {
    throw new Error("Unauthorized");
  }

  try {
    const response = await axios.post(
      `${API_URL}/admin/users/${userId}/credits`,
      { amount },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(`Error updating credits for user ${userId}:`, error);
    throw error;
  }
};

export const submitContactForm = async (formData, token) => {
  if (!token) {
    throw new Error("Unauthorized");
  }

  const response = await fetch(`${API_URL}/contact/submit`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`, // No Content-Type for FormData
    },
    body: formData, // Directly pass FormData
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || "Failed to submit contact form");
  }

  return response.json();
};
