import React, { useState, useEffect, useContext } from "react"; // Correct

import "../Dashboard.css";
import dayjs from "dayjs";
import {
  getUserJobs,
  addJobLink,
  updateJobLink,
  tailorResume,
  tailorCoverLetter,
  deleteJobLink,
  getUserProfile,
} from "../api";
import { useNavigate } from "react-router-dom"; // Add this at the top
import JobsTable from "../components/JobsTable"; // Import the new JobsTable component
import ThemeToggle from "../ThemeToggle";
import Panels from "../components/Panels";
import ButtonsSection from "../components/ButtonSection"; // Adjust path as needed
import AddJobButton from "../components/AddJobButton";
import BulkActionsDropdown from "../components/BulkActionsDropdown";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useWebSocket from "../hooks/useWebSocket"; // Adjust the path as necessary
import { AuthContext } from "../context/AuthContext"; // Import AuthContext
import axios from "axios";
import ResumeUploadModal from "../components/ResumeUploadModal";
import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";

const Dashboard = () => {
  const navigate = useNavigate(); // Get the navigate function
  const { isAuthenticated, user, logout } = useContext(AuthContext); // Use AuthContext
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [jobToDelete, setJobToDelete] = useState(null);
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [selectMode, setSelectMode] = useState(false);
  const [confirmationText, setConfirmationText] = useState("");
  const [progress, setProgress] = useState(0); // To track progress
  const [firstName, setFirstName] = useState(""); // State to hold the user's first name
  const { getAccessTokenSilently } = useAuth0();

  const [resumeCount, setResumeCount] = useState(0);
  const [coverLetterCount, setCoverLetterCount] = useState(0);
  const [recentActivitiesCount, setRecentActivitiesCount] = useState(0);
  const [jobs, setJobs] = useState([]);
  const [jobsCount, setJobsCount] = useState(0); // New state for filtered jobs count

  const [creditBalance, setCreditBalance] = useState(0);
  const [userProfile, setUserProfile] = useState(null); // State for user profile

  // import CLIENT_URL from environment variables
  const WS_URL = process.env.REACT_APP_WS_URL || "wss://applimatch.co";
  const API_URL = process.env.REACT_APP_API_URL || "http://localhost:5000/api";

  useEffect(() => {
    if (!isAuthenticated) return;

    const fetchData = async () => {
      try {
        // Request the token with the correct audience and scope.
        const token = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          },
        });

        // (Optional) Log the decoded token payload for debugging.
        console.log("Decoded token:", JSON.parse(atob(token.split(".")[1])));

        // Now use the token to fetch the backend profile.
        const profile = await getUserProfile(token);
        setUserProfile(profile);

        // And fetch jobs using the same token.
        const userJobs = await getUserJobs(token);
        setJobs(userJobs);
      } catch (err) {
        console.error("Error in fetchData:", err);
        // Redirect to login if the token is missing/invalid.
        navigate("/login");
      }
    };

    fetchData();
  }, [isAuthenticated, navigate, getAccessTokenSilently]);

  const updateMetrics = (jobs) => {
    // Calculate jobsCount by filtering out incomplete jobs (e.g., missing key fields)
    const completedJobs = jobs.filter(
      (job) => job.jobLink && job.company && job.jobTitle
    );

    const tailoredResumes = completedJobs.filter(
      (job) => job.resumeTailored
    ).length;
    const tailoredCoverLetters = completedJobs.filter(
      (job) => job.coverLetterTailored
    ).length;
    const recentActivities =
      tailoredResumes + tailoredCoverLetters + completedJobs.length;

    setResumeCount(tailoredResumes);
    setCoverLetterCount(tailoredCoverLetters);
    setRecentActivitiesCount(recentActivities);
    setJobsCount(completedJobs.length);
  };

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const token = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          },
        });
        const fetchedJobs = await getUserJobs(token); // Get jobs from backend
        console.log("Raw API jobs response:", fetchedJobs);

        const mappedJobs = fetchedJobs.map((job) => ({
          jobId: job.Job_ID,
          jobLink: job.job_link || "",
          company: job.company_name,
          jobTitle: job.job_title,
          resumeTailored: job.resume_tailored,
          coverLetterTailored: job.coverletter_tailored,
          loadingResume: false,
          loadingCoverLetter: false,
          resumeLink: job.resume_link || null,
          coverLetterLink: job.cover_letter_link || null,
          resumeCreationDate: job.resume_creation_date || null,
          coverLetter_CreationDate: job.coverLetter_CreationDate || null,
          coverLetterID: job.coverLetter_ID || null,
          isEditing: false,
        }));

        console.log("Mapped jobs data in the frontend:", mappedJobs);
        setJobs(mappedJobs);
        updateMetrics(mappedJobs);

        // Calculate metrics based on the mapped jobs
        const tailoredResumes = mappedJobs.filter(
          (job) => job.resumeTailored
        ).length;
        const tailoredCoverLetters = mappedJobs.filter(
          (job) => job.coverLetterTailored
        ).length;
        const recentActivities =
          tailoredResumes + tailoredCoverLetters + mappedJobs.length;

        // Calculate jobsCount by filtering out incomplete jobs (e.g., missing key fields)
        const completedJobsCount = mappedJobs.filter(
          (job) => job.jobLink && job.company && job.jobTitle
        ).length;

        setResumeCount(tailoredResumes);
        setCoverLetterCount(tailoredCoverLetters);
        setRecentActivitiesCount(recentActivities);
        setJobsCount(completedJobsCount);
      } catch (error) {
        console.error("Error fetching jobs:", error);
      }
    };

    fetchJobs();
  }, []);

  const handleCheckboxChange = (jobId) => {
    if (selectedJobs.includes(jobId)) {
      setSelectedJobs(selectedJobs.filter((id) => id !== jobId));
    } else {
      setSelectedJobs([...selectedJobs, jobId]);
    }
  };

  const handleSelectMode = () => {
    setSelectMode(!selectMode);
    setSelectedJobs([]); // Reset selections when entering/exiting select mode
  };
  // Fetch initial credit balance on component mount
  useEffect(() => {
    const fetchCreditBalance = async () => {
      try {
        const token = await getAccessTokenSilently();
        console.log(
          "token getAccessTokenSilently",
          JSON.parse(atob(token.split(".")[1]))
        ); // decode JWT payload
        const response = await axios.get(`${API_URL}/users/me`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCreditBalance(response.data.credit_balance);
      } catch (error) {
        console.error("Error fetching credit balance:", error);
        toast.error("Failed to fetch credit balance.");
      }
    };

    fetchCreditBalance();
  }, []);

  // Initialize WebSocket and handle credit updates
  const onCreditUpdate = useCallback((newBalance) => {
    setCreditBalance(newBalance);
  }, []);

  useWebSocket(WS_URL, onCreditUpdate);

  const handleBulkAction = async (action) => {
    if (action === "delete") {
      if (selectedJobs.length > 0) {
        setShowDeleteModal(true); // Show delete modal if there are selected jobs
        setJobToDelete(null); // Ensure single job is not set during bulk delete
      } else {
        toast.error("Please select at least one job.");
      }
    } else {
      try {
        // Fetch the latest credit balance
        const token = await getAccessTokenSilently();
        const profile = await getUserProfile(token);
        let currentCreditBalance = profile.credit_balance;

        let creditsNeeded = selectedJobs.length; // 1 credit per job

        if (creditsNeeded > currentCreditBalance) {
          toast.error(
            "You do not have enough credits to perform this action. Please purchase more credits."
          );
          return;
        }

        // if no jobs are selected, return early
        if (selectedJobs.length === 0) {
          toast.error("Please select at least one job.");
          return;
        }

        // Proceed with bulk action
        const tailoringPromises = selectedJobs.map(async (jobId) => {
          const index = jobs.findIndex((job) => job.jobId === jobId);
          if (index !== -1) {
            try {
              switch (action) {
                case "tailorResume":
                  await handleTailor(index, "resume");
                  break;
                case "tailorCoverLetter":
                  await handleTailor(index, "coverLetter");
                  break;
                default:
                  break;
              }
              // Decrement the local credit balance
              currentCreditBalance -= 1;
              setCreditBalance(currentCreditBalance);
            } catch (error) {
              // Handle errors per job
              console.error(`Error tailoring job ${jobId}:`, error);
              toast.error(`Error tailoring job ${jobId}: ${error.message}`);
              // Optionally, you can choose to stop or continue based on the error
            }
          }
        });

        // Wait for all tailoring promises to complete
        await Promise.all(tailoringPromises);

        setSelectMode(false);
        setSelectedJobs([]); // Clear selections after bulk action
      } catch (error) {
        console.error("Error fetching user profile:", error);
        toast.error("Failed to fetch user profile. Please try again.");
      }
    }
  };

  const handleAddRow = () => {
    const newJob = {
      jobId: null, // Temporary ID, use an actual ID from backend later
      jobLink: "",
      company: "",
      jobTitle: "",
      resumeTailored: false,
      coverLetterTailored: false,
      loadingResume: false,
      loadingCoverLetter: false,
      resumeLink: null,
      coverLetterLink: null,
      resumeCreationDate: null,
      coverLetter_CreationDate: null,
      coverLetterID: null,
      isEditing: true, // New job starts in editing mode
    };
    const updatedJobs = [...jobs, newJob];
    setJobs(updatedJobs);
    updateMetrics(updatedJobs); // Update metrics after adding a new job
  };

  const handleInputChange = (index, field, value) => {
    console.log(
      `Updating job at index ${index} field ${field} with value:`,
      value
    );

    const newJobs = [...jobs];
    newJobs[index][field] = value;
    setJobs(newJobs);
  };

  const handleSaveJob = async (index) => {
    const job = jobs[index];
    let jobLink = job.jobLink;

    // Check if the URL starts with http:// or https://
    if (!/^https?:\/\//i.test(jobLink)) {
      jobLink = `http://${jobLink}`; // Default to http if protocol is missing
    }

    const jobData = {
      company_name: job.company,
      job_title: job.jobTitle,
      job_link: jobLink, // Use the validated jobLink
    };

    try {
      let updatedJobs = [...jobs];
      updatedJobs[index].loading = true; // Set loading to true
      setJobs(updatedJobs);

      const token = await getAccessTokenSilently({
        authorizationParams: { audience: process.env.REACT_APP_AUTH0_AUDIENCE },
      });

      if (job.jobId) {
        await updateJobLink(job.jobId, jobData, token);
        updatedJobs[index].isEditing = false;
      } else {
        const response = await addJobLink(jobData, token);
        console.log("Response from addJobLink:", response);

        if (response.error) {
          toast.error(response.error);
          return;
        }
        updatedJobs[index].jobId = response.jobId;
        updatedJobs[index].isEditing = false;
        updatedJobs[index].resumeTailored = false;
        updatedJobs[index].coverLetterTailored = false;
      }
      updatedJobs[index].loading = false; // Set loading to false
      setJobs(updatedJobs);
      updateMetrics(updatedJobs); // Update metrics after saving the job
      toast.success("Job saved successfully!");
    } catch (error) {
      toast.error(
        "Error scraping job description. Please check the URL and try again."
      );
      console.error("Error saving job:", error);
      let updatedJobs = [...jobs];
      updatedJobs[index].loading = false; // Ensure loading is set to false on error
      setJobs(updatedJobs);
    }
  };

  const handleDeleteJob = async () => {
    // Retrieve the token first
    let token;
    try {
      token = await getAccessTokenSilently({
        authorizationParams: { audience: process.env.REACT_APP_AUTH0_AUDIENCE },
      });
    } catch (err) {
      console.error("Error retrieving token:", err);
      toast.error("Authentication error. Please log in again.");
      return;
    }
    const jobsToDelete =
      selectedJobs.length > 0 ? selectedJobs : [jobToDelete?.jobId]; // Use selectedJobs or jobToDelete

    if (jobsToDelete.length === 0) {
      alert("No jobs selected for deletion.");
      return;
    }

    try {
      for (const jobId of jobsToDelete) {
        await deleteJobLink(jobId, token); // Pass token here
      }

      const updatedJobs = jobs.filter(
        (job) => !jobsToDelete.includes(job.jobId)
      );
      setJobs(updatedJobs);
      setSelectedJobs([]); // Clear selections after deletion
      closeDeleteModal(); // Close the modal after deletion
    } catch (error) {
      console.error("Error deleting job(s):", error.message);
    }
  };

  const openDeleteModal = (index) => {
    if (selectedJobs.length > 0) {
      setShowDeleteModal(true); // Open modal for bulk delete
      setJobToDelete(null); // Ensure we're in bulk delete mode, no single job
    } else {
      const job = jobs[index];
      if (!job) {
        console.error("Job not found.");
        return;
      }
      setJobToDelete(job); // Set the single job to be deleted
      setShowDeleteModal(true); // Show the delete modal
    }
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setJobToDelete(null);
    setConfirmationText("");
  };

  // When tailoring cover letters, the status should update and reflect correctly
  const handleTailor = async (index, field) => {
    const job = jobs[index];
    const jobId = job.jobId;

    if (!jobId) {
      console.error("Error: jobId is undefined.");
      return;
    }

    // Retrieve token
    let token;
    try {
      token = await getAccessTokenSilently({
        authorizationParams: { audience: process.env.REACT_APP_AUTH0_AUDIENCE },
      });
    } catch (err) {
      console.error("Error retrieving token:", err);
      toast.error("Authentication error. Please log in again.");
      return;
    }

    const newJobs = [...jobs];
    if (field === "resume") {
      newJobs[index].loadingResume = true;
    } else if (field === "coverLetter") {
      newJobs[index].loadingCoverLetter = true;
    }
    setJobs(newJobs);

    try {
      let tailoredData;
      const updateData = {
        company_name: job.company,
        job_title: job.jobTitle,
        job_link: job.jobLink,
      };
      if (field === "resume") {
        tailoredData = await tailorResume(jobId, undefined, token); // pass token
        const currentTime = dayjs().format("MM/DD/YYYY h:mm A");

        updateData.resume_tailored = true;
        updateData.resume_link = tailoredData.link;
        // updateData.resume_creation_date = currentTime; // Remove this line

        newJobs[index].loadingResume = false;
        newJobs[index].resumeTailored = true;
        newJobs[index].resumeLink = tailoredData.link;
        // newJobs[index].resumeCreationDate = currentTime; // Remove or handle locally
      } else if (field === "coverLetter") {
        tailoredData = await tailorCoverLetter(jobId, token); // pass token
        const currentTime = dayjs().format("MM/DD/YYYY h:mm A");

        updateData.coverletter_tailored = true;
        updateData.cover_letter_link = tailoredData.link;
        // updateData.coverLetter_CreationDate = currentTime; // Remove this line

        newJobs[index].loadingCoverLetter = false;
        newJobs[index].coverLetterTailored = true;
        newJobs[index].coverLetterLink = tailoredData.link;
        // newJobs[index].coverLetter_CreationDate = currentTime; // Remove or handle locally
      }

      await updateJobLink(jobId, updateData, token);
      setJobs(newJobs);
      updateMetrics(newJobs);
    } catch (error) {
      console.error("Error tailoring:", error);
      toast.error(error.message); // Display the error using toast

      const resetJobs = [...jobs];
      if (field === "resume") {
        resetJobs[index].loadingResume = false;
      } else if (field === "coverLetter") {
        resetJobs[index].loadingCoverLetter = false;
      }
      setJobs(resetJobs);
    }
  };

  const handleSelectAll = () => {
    if (selectedJobs.length === jobs.length) {
      // If all are selected, deselect all
      setSelectedJobs([]); // Clear the selection
    } else {
      // Select all job IDs
      const allJobIds = jobs.map((job) => job.jobId);
      setSelectedJobs(allJobIds);
    }
  };

  const handleSort = (criteria) => {
    const sortedJobs = [...jobs];
    if (criteria === "chronological") {
      sortedJobs.sort(
        (a, b) =>
          new Date(b.resumeCreationDate) - new Date(a.resumeCreationDate)
      );
    } else if (criteria === "alphabetical") {
      sortedJobs.sort((a, b) => a.company.localeCompare(b.company));
    }
    setJobs(sortedJobs);
    localStorage.setItem("jobSortPreference", criteria); // Save sorting preference
  };

  useEffect(() => {
    const savedSortPreference = localStorage.getItem("jobSortPreference");
    if (savedSortPreference) {
      handleSort(savedSortPreference); // Apply saved sorting preference
    }
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    //console.log("openModal called"); // Debug log
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="dashboard-container">
      <div className="dashboard-header">
        <h2>Dashboard</h2>
        <ButtonsSection openModal={openModal} />
        <ResumeUploadModal isOpen={isModalOpen} onClose={closeModal} />
      </div>
      {/*<p>Welcome back, {firstName}!</p> {/* Display the first name here */}
      <Panels
        jobsCount={jobsCount}
        resumeCount={resumeCount}
        coverLetterCount={coverLetterCount}
        recentActivitiesCount={recentActivitiesCount}
      />
      {showDeleteModal && (
        <div className="modal">
          <div className="modal-content">
            {selectedJobs.length > 0 ? (
              <>
                <p>
                  Are you sure you want to delete the selected{" "}
                  {selectedJobs.length} jobs?
                </p>
                <div className="modal-button-container">
                  <button
                    className="modal-button confirm-button"
                    onClick={handleDeleteJob}
                  >
                    Yes
                  </button>
                  <button
                    className="modal-button modal-cancel-button"
                    onClick={closeDeleteModal}
                  >
                    No
                  </button>
                </div>
              </>
            ) : jobToDelete ? (
              <>
                <p>
                  Are you sure you want to delete the job at{" "}
                  {jobToDelete.company}?
                </p>
                <div className="modal-button-container">
                  <button
                    className="modal-button confirm-button"
                    onClick={handleDeleteJob}
                  >
                    Yes
                  </button>
                  <button
                    className="modal-button cancel-button"
                    onClick={closeDeleteModal}
                  >
                    No
                  </button>
                </div>
              </>
            ) : (
              <p>No job selected for deletion.</p>
            )}
          </div>
        </div>
      )}
      {/* Render JobsTable Component */}
      <div className="jobs-header">
        <h2 className="jobs-title">Jobs</h2>

        <div className="header-right">
          <div className="top-actions">
            <select
              onChange={(e) => handleSort(e.target.value)}
              defaultValue=""
              id="sort-by-dropdown"
            >
              <option value="" disabled className="sort-by-dropdown-item">
                Sort by
              </option>
              <option value="chronological">Chronological</option>
              <option value="alphabetical">Alphabetical</option>
            </select>
          </div>
          <BulkActionsDropdown
            selectMode={selectMode}
            handleBulkAction={handleBulkAction}
          />

          <div className="action-buttons-container">
            <AddJobButton onClick={() => handleAddRow()} />
          </div>
        </div>
      </div>

      <JobsTable
        jobs={jobs}
        selectMode={selectMode}
        selectedJobs={selectedJobs}
        setSelectedJobs={setSelectedJobs} // Pass setSelectedJobs to JobsTable
        progress={progress}
        handleCheckboxChange={handleCheckboxChange}
        handleInputChange={handleInputChange}
        handleTailor={handleTailor}
        handleSaveJob={handleSaveJob}
        openDeleteModal={openDeleteModal}
        handleAddRow={handleAddRow}
        setJobs={setJobs} // Pass setJobs to handle edits
      />
      <ToastContainer />
    </div>
  );
};

export default Dashboard;
