// src/context/AuthContext.js
import React, { createContext, useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getUserProfile } from "../api"; // our API helper to call /users/me

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const {
    loginWithRedirect,
    logout: auth0Logout,
    user: auth0User,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
  } = useAuth0();

  const [userProfile, setUserProfile] = useState(null);
  const [creditBalance, setCreditBalance] = useState(0);
  const [user, setUser] = useState(null);

  // Update basic Auth0 user state
  useEffect(() => {
    setUser(auth0User || null);
  }, [auth0User]);

  // Helper: decode a JWT token payload for debugging
  const decodeTokenPayload = (token) => {
    try {
      const parts = token.split(".");
      if (parts.length === 3) {
        return JSON.parse(atob(parts[1]));
      } else {
        console.warn("Token does not have three parts:", token);
        return null;
      }
    } catch (e) {
      console.warn("Error decoding token payload:", e);
      return null;
    }
  };

  // Fetch the backend profile (/users/me) once authenticated.
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const token = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          },
        });

        // (Optional) Log token payload for debugging
        const decoded = decodeTokenPayload(token);
        console.log("Decoded token:", decoded);

        // Fetch the full user profile from your backend
        const profile = await getUserProfile(token);
        if (process.env.NODE_ENV === "development") {
          console.log("Fetched user profile:", profile);
        }
        setUserProfile(profile);
        setCreditBalance(profile.credit_balance); // Update credit balance
      } catch (err) {
        console.error("Error fetching user profile in AuthContext:", err);
      }
    };

    if (isAuthenticated) {
      fetchProfile();
    } else {
      setUserProfile(null);
      setCreditBalance(0);
    }
  }, [isAuthenticated, getAccessTokenSilently, getAccessTokenWithPopup]);

  // Handlers for login and signup (using redirect)
  const handleLogin = async (rememberMe) => {
    try {
      await loginWithRedirect({
        authorizationParams: {
          redirect_uri: window.location.origin,
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: rememberMe
            ? "openid profile email offline_access"
            : "openid profile email",
        },
      });
    } catch (err) {
      console.error("Login failed:", err);
      throw err;
    }
  };

  const handleSignup = async () => {
    try {
      await loginWithRedirect({
        authorizationParams: {
          screen_hint: "signup",
          redirect_uri: window.location.origin,
        },
      });
    } catch (err) {
      console.error("Signup failed:", err);
      throw err;
    }
  };

  const logout = () => {
    setUser(null);
    setUserProfile(null);
    setCreditBalance(0);
    auth0Logout({
      logoutParams: {
        returnTo: process.env.REACT_APP_AUTH0_LOGOUT_URL,
      },
    });
  };

  // Helper to build authenticated headers for API requests
  const getAuthenticatedHeaders = async () => {
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "openid profile email",
    });
    return {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
  };

  return (
    <AuthContext.Provider
      value={{
        userProfile,
        creditBalance,
        isAuthenticated,
        isLoading,
        loginWithRedirect,
        logout: auth0Logout,
        getAccessTokenSilently,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
