import React from "react";
import "../styles.css"; // Assuming a dedicated stylesheet for this page

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <div className="container">
        <h1 className="title">Privacy Policy for Applimatch</h1>
        <section>
          <h2>Introduction</h2>
          <p>
            Welcome to Applimatch, an innovative job application assistant
            designed to streamline your job search by creating customized
            resumes and cover letters tailored for each application. At
            Applimatch, we respect and protect your privacy. This Privacy Policy
            outlines how we collect, use, share, and protect your personal
            information, your rights concerning this information, and the
            limitations of our liability.
          </p>
          <p>
            For questions or concerns regarding this Privacy Policy, or to
            exercise your privacy rights, please reach out to us at:
          </p>
          <address>
            <strong>Applimatch</strong>
            Burlingame, CA 94010
            <br />
            Email:{" "}
            <a href="mailto:contact@applimatch.com">contact@applimatch.com</a>
          </address>
        </section>

        <section>
          <h2>1. How We Collect and Use Your Information</h2>
          <p>
            To provide our services, Applimatch may collect the following types
            of information:
          </p>
          <ul>
            <li>
              <strong>Personal Information:</strong> We collect your name, email
              address, job history, and educational background to generate
              resumes and cover letters customized for each job application.
            </li>
            <li>
              <strong>Job Preferences:</strong> We may ask about your desired
              job roles, career goals, and other preferences to better tailor
              our recommendations.
            </li>
            <li>
              <strong>Payment Information:</strong> When you make a payment
              through Applimatch, your payment information is collected and
              processed securely by Stripe (see <em>Payment Processing</em>{" "}
              section below).
            </li>
            <li>
              <strong>Usage Data:</strong> We gather data on your interactions
              with the app, including usage frequency and feature engagement, to
              improve the app’s functionality and user experience.
            </li>
            <li>
              <strong>AI-Powered Services:</strong> Applimatch utilizes OpenAI’s
              API to process requests for resume and cover letter generation.
              Limited data is sent to OpenAI solely for delivering these
              services.
            </li>
            <li>
              <strong>Aggregated and Non-Identifiable Information:</strong> This
              data may be used internally for research and analysis to improve
              our services.
            </li>
          </ul>
        </section>

        <section>
          <h2>2. Cookies and Tracking Technologies</h2>
          <p>
            Applimatch uses cookies and similar tracking technologies to enhance
            functionality and understand user preferences. These technologies
            help us analyze app performance and provide a better experience. ●
            By using our services, you consent to the use of cookies. You may
            manage your cookie preferences in your browser or device settings,
            but disabling cookies may affect certain app features.
          </p>
        </section>

        <section>
          <h2>3. Sharing Information with Third Parties</h2>
          <p>
            Applimatch values your privacy and does not sell your personal
            information. We share your information only with trusted third-party
            providers who help us deliver our services, such as data hosting,
            analytics, and payment processing providers. ● These third parties
            are obligated to maintain confidentiality and security and to use
            your information only for authorized purposes.
          </p>
        </section>

        <section>
          <h2>4. Payment Processing</h2>
          <p>
            Applimatch uses Stripe, a secure third-party payment processor, to
            handle payments. When you make a payment, your credit card or
            payment information is collected and processed directly by Stripe,
            and Applimatch does not store this information. Stripe’s use of your
            data is governed by its own Privacy Policy, available at Stripe
            Privacy Policy. See Stripe’s{" "}
            <a
              href="https://stripe.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            .
          </p>
        </section>

        <section>
          <h2>5. Data Storage and Retention</h2>
          <p>
            We store your personal information on secure servers and retain it
            as long as necessary to provide our services or as required by law.
            Upon deactivating your account or upon a verified deletion request,
            we will delete your personal information except as needed to fulfill
            legal obligations.
          </p>
        </section>

        <section>
          <h2>6. Security Measures</h2>
          <p>
            Applimatch implements industry-standard security measures, including
            encryption and secure server environments, to protect your data.
            However, no system is entirely foolproof. Applimatch cannot
            guarantee absolute security and is not liable for unauthorized
            access resulting from external factors or user negligence. ● Users
            are encouraged to secure their accounts by using strong passwords
            and safeguarding their login credentials.
          </p>
        </section>

        <section>
          <h2>7. User Rights</h2>
          <p>
            Your rights regarding your personal information depend on your
            location and applicable privacy laws. Below is an overview of rights
            that may apply to you:
          </p>
          <p>
            <strong>
              For Individuals Outside the United States (e.g., GDPR
              jurisdictions):
            </strong>
            If you are located in Europe or other jurisdictions covered by the
            General Data Protection Regulation (GDPR) or similar laws, you may
            have the following rights:
          </p>
          <ul>
            <li>
              <strong>Right to Be Informed:</strong> To know how your personal
              data is collected, used, and processed.
            </li>
            <li>
              <strong>Right of Access:</strong> To request a copy of the
              personal data we hold about you.
            </li>
            <li>
              <strong>Right to Rectification:</strong> To request corrections to
              inaccurate or incomplete data.
            </li>
            <li>
              <strong>Right to Erasure ("Right to Be Forgotten"):</strong> To
              request deletion of your data, subject to legal exceptions.
            </li>
            <li>
              <strong>Right to Restrict Processing:</strong> To request
              limitations on how your data is processed in specific
              circumstances.
            </li>
            <li>
              <strong>Right to Data Portability:</strong> To receive a copy of
              your data in a structured, commonly used, and machine-readable
              format and transfer it to another service.
            </li>
            <li>
              <strong>Right to Object:</strong> To object to data processing in
              certain situations, such as for direct marketing purposes.
            </li>
            <li>
              <strong>
                Rights Related to Automated Decision-Making and Profiling:
              </strong>{" "}
              To not be subject to decisions based solely on automated
              processing, including profiling, if such decisions significantly
              affect you.
            </li>
          </ul>
          <p>
            <strong>For Individuals in the United States:</strong>
            <br />
            GDPR rights do not apply in the United States. Instead, your rights
            depend on state-specific privacy laws. While federal law does not
            provide comprehensive data privacy rights, some states (e.g.,
            California, Colorado, Virginia, Utah, Connecticut, and Iowa) have
            enacted their own privacy laws, which may include:
          </p>
          <ul>
            <li>
              <strong>Right to Access:</strong> You can request a copy of the
              personal data we hold about you.
            </li>
            <li>
              <strong>Right to Correct:</strong> You may request corrections to
              inaccurate personal data.
            </li>
            <li>
              <strong>Right to Delete:</strong> You can request that we delete
              your personal data, subject to certain exceptions.
            </li>
            <li>
              <strong>
                Right to Opt-Out of Sale or Sharing of Personal Data:
              </strong>{" "}
              In some states, you may opt out of the sale or sharing of your
              personal information.
            </li>
            <li>
              <strong>Right to Non-Discrimination:</strong> You have the right
              not to face discrimination for exercising your privacy rights.
            </li>
          </ul>
          <p>
            State laws vary, so the specific rights available to you depend on
            your location. To exercise your rights, please contact us at{" "}
            <a href="mailto:support@applimatch.com">support@applimatch.com</a>.
            Applimatch may require verification of your identity before
            fulfilling your request.
          </p>
        </section>

        <section>
          <h2>8. Limitation of Liability</h2>
          <p>
            Applimatch is a tool designed to assist with job application
            materials but does not guarantee employment outcomes, including
            securing interviews or jobs. Numerous factors, such as employer
            preferences and market conditions, are beyond our control.
          </p>
          <p>
            To the extent permitted by law, Applimatch disclaims all warranties,
            including implied warranties of merchantability and fitness for a
            particular purpose.
          </p>
          <p>
            Applimatch’s total liability for any claim will not exceed the
            amount paid by the user in the 12 months preceding the claim.
            Applimatch is not liable for indirect, incidental, or consequential
            damages.
          </p>
        </section>

        <section>
          <h2>9. International Data Transfers </h2>
          <p>
            If your data is transferred outside your country of residence, we
            take appropriate safeguards, such as Standard Contractual Clauses,
            to ensure your data is protected.
          </p>
        </section>

        <section>
          <h2>10. Children’s Data</h2>
          <p>
            Applimatch is intended for individuals aged 18 and older. We do not
            knowingly collect personal information from children under 13. If we
            become aware of such data, we will promptly delete it.
          </p>
        </section>
        <section>
          <h2>11. Updates to This Privacy Policy</h2>
          <p>
            This Privacy Policy may be updated periodically. Significant changes
            will be communicated via email or in-app notifications. Continued
            use of our services constitutes acceptance of the updated policy.
          </p>
        </section>

        <section>
          <h2>12. Questions or Concerns</h2>
          <p>
            For any questions or concerns, please contact us at:
            <br />
            Email:{" "}
            <a href="mailto:contact@applimatch.com">contact@applimatch.com</a>
          </p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
