import React from "react";
import "../styles.css"; // Assuming a dedicated stylesheet for this page

const DPA = () => {
  return (
    <div className="privacy-policy">
      <div className="container">
        <h1 className="title">Data Processing Addendum (DPA)</h1>

        <section>
          <p>
            This Data Processing Addendum (“DPA”) forms part of the agreement
            (“Agreement”) between AppliMatch (“Data Processor”) and its
            customers (“Data Controller”) concerning the processing of Personal
            Data in connection with the services provided by AppliMatch. This
            DPA reflects the parties’ commitment to comply with the General Data
            Protection Regulation (GDPR), the California Consumer Privacy Act
            (CCPA), and other applicable data protection laws.
          </p>
        </section>

        <section>
          <h2>1. Definitions</h2>
          <ul>
            <li>
              <strong>Personal Data:</strong> Any information relating to an
              identified or identifiable natural person (“Data Subject”), as
              defined under GDPR and CCPA.
            </li>
            <li>
              <strong>Processing:</strong> Any operation performed on Personal
              Data, including collection, storage, use, disclosure, or deletion.
            </li>
            <li>
              <strong>Data Controller:</strong> The entity that determines the
              purposes and means of processing Personal Data.
            </li>
            <li>
              <strong>Data Processor:</strong> The entity that processes
              Personal Data on behalf of the Data Controller.
            </li>
            <li>
              <strong>Sub-processor:</strong> Any third party engaged by the
              Data Processor to assist in processing Personal Data.
            </li>
            <li>
              <strong>Applicable Data Protection Law:</strong> The GDPR, CCPA,
              and any other applicable data protection or privacy laws.
            </li>
          </ul>
        </section>

        <section>
          <h2>2. Scope and Purpose of Processing</h2>
          <p>
            <strong>Purpose:</strong> The Data Processor will process Personal
            Data as necessary to deliver its services, including:
          </p>
          <ul>
            <li>Generating tailored resumes and cover letters.</li>
            <li>Managing user profiles and application preferences.</li>
            <li>
              Enhancing service quality through AI-based tools and analytics.
            </li>
            <li>
              Facilitating payment processing for AppliMatch services via
              Stripe.
            </li>
          </ul>
          <p>
            <strong>Scope:</strong> The scope of processing includes storing,
            accessing, and processing data provided by the Data Controller, and
            transmitting data to Sub-processors to fulfill service obligations.
          </p>
          <p>
            <strong>Nature of Processing:</strong> Processing involves automated
            analysis, storage, and transfer of data within the scope of
            AppliMatch’s services.
          </p>
        </section>

        <section>
          <h2>3. Obligations of the Data Processor</h2>
          <ul>
            <li>
              Process Personal Data only in accordance with the Data
              Controller’s documented instructions.
            </li>
            <li>
              Implement appropriate technical and organizational measures to
              safeguard Personal Data against unauthorized or unlawful
              processing, loss, destruction, or damage.
            </li>
            <li>
              Ensure that all personnel authorized to process Personal Data are
              bound by confidentiality agreements.
            </li>
            <li>
              Notify the Data Controller of any suspected or actual data
              breaches without undue delay, but no later than 72 hours after
              becoming aware of the breach.
            </li>
            <li>
              Assist the Data Controller in complying with its obligations under
              GDPR and CCPA, including responding to Data Subject requests.
            </li>
            <li>
              Retain Personal Data only as long as necessary to fulfill
              contractual obligations or comply with legal requirements. Upon
              termination of the DPA, securely delete Personal Data unless
              otherwise required by law.
            </li>
          </ul>
        </section>

        <section>
          <h2>4. Data Controller Responsibilities</h2>
          <ul>
            <li>
              Ensure that the Personal Data provided to the Data Processor
              complies with GDPR, CCPA, and other applicable laws.
            </li>
            <li>
              Obtain any necessary consents from Data Subjects for the
              processing of Personal Data.
            </li>
            <li>
              Provide clear, lawful, and documented instructions to the Data
              Processor regarding data processing activities.
            </li>
          </ul>
        </section>

        <section>
          <h2>5. Sub-processors</h2>
          <p>
            The Data Processor may engage Sub-processors for specific processing
            activities, including:
          </p>
          <ul>
            <li>OpenAI for API-based data analysis and AI functionalities.</li>
            <li>Stripe for payment processing services.</li>
            <li>AWS for cloud hosting.</li>
          </ul>
          <p>
            The Data Processor shall ensure that Sub-processors are bound by
            contractual obligations that meet the standards of this DPA. The
            Data Controller will be informed of any changes to the list of
            Sub-processors and may object to such changes within 14 days of
            notification.
          </p>
        </section>

        <section>
          <h2>6. Data Subject Rights</h2>
          <ul>
            <li>
              Assist the Data Controller in fulfilling its obligation to respond
              to Data Subject requests regarding access, rectification,
              deletion, restriction of processing, and data portability.
            </li>
            <li>
              Comply with CCPA rights, including the right to know what Personal
              Data is collected, the right to request deletion, and the right to
              opt out of the sale of Personal Data.
            </li>
            <li>
              Not directly respond to Data Subject requests without prior
              written consent from the Data Controller unless legally required
              to do so.
            </li>
          </ul>
          <p>
            AppliMatch does not sell Personal Data as defined under the CCPA.
          </p>
        </section>

        <section>
          <h2>7. Data Transfers</h2>
          <p>
            If the Data Processor transfers Personal Data outside the European
            Economic Area (EEA) or California, it will ensure appropriate
            safeguards, such as Standard Contractual Clauses or binding
            corporate rules.
          </p>
        </section>

        <section>
          <h2>8. Security Measures</h2>
          <ul>
            <li>Encryption of Personal Data in transit and at rest.</li>
            <li>
              Access controls to restrict unauthorized access to systems and
              data.
            </li>
            <li>Regular audits and assessments of security practices.</li>
            <li>
              Secure storage of Personal Data using AWS with appropriate
              encryption and redundancy safeguards.
            </li>
          </ul>
        </section>

        <section>
          <h2>9. Data Breach Notification</h2>
          <ul>
            <li>
              Notify the Data Controller within 72 hours of discovering a data
              breach.
            </li>
            <li>
              Provide details about the breach, its potential impact, and the
              measures taken to mitigate it.
            </li>
          </ul>
        </section>

        <section>
          <h2>10. Termination and Data Deletion</h2>
          <ul>
            <li>
              Upon termination of the Agreement or at the Data Controller’s
              request, delete or return all Personal Data unless required by law
              to retain it.
            </li>
            <li>Certify the deletion of Personal Data upon request.</li>
          </ul>
        </section>

        <section>
          <h2>11. Audits</h2>
          <ul>
            <li>
              The Data Controller may audit the Data Processor’s compliance with
              this DPA upon providing reasonable notice.
            </li>
            <li>
              The Data Processor shall provide documentation or access necessary
              to verify compliance.
            </li>
          </ul>
        </section>

        <section>
          <h2>12. Liability</h2>
          <ul>
            <li>
              The Data Processor’s liability for breaches of this DPA is limited
              as outlined in the Agreement, unless prohibited by applicable law.
            </li>
            <li>
              The Data Processor shall not be liable for damages resulting from
              the actions or omissions of Sub-processors, unless it failed to
              take reasonable measures to ensure their compliance.
            </li>
            <li>
              In no event shall the aggregate liability exceed the total fees
              paid by the Data Controller for services in the 12 months
              preceding the claim.
            </li>
          </ul>
        </section>

        <section>
          <h2>13. Governing Law and Dispute Resolution</h2>
          <ul>
            <li>
              This DPA is governed by the same laws as the Agreement between the
              Data Controller and the Data Processor.
            </li>
            <li>
              Any disputes arising from or related to this DPA shall be resolved
              through binding arbitration in accordance with the rules of the
              American Arbitration Association (AAA).
            </li>
          </ul>
        </section>

        <section>
          <h2>Appendix A: Sub-processors</h2>
          <table>
            <thead>
              <tr>
                <th>Sub-Processor Name</th>
                <th>Service Provided</th>
                <th>Location</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>OpenAI</td>
                <td>AI-based data processing</td>
                <td>United States</td>
              </tr>
              <tr>
                <td>AWS</td>
                <td>Cloud hosting</td>
                <td>Global</td>
              </tr>
              <tr>
                <td>Stripe</td>
                <td>Payment processing</td>
                <td>Global</td>
              </tr>
            </tbody>
          </table>
          <p>
            <strong>Effective Date:</strong> This DPA is effective as of the
            date of the Agreement between AppliMatch and the Data Controller.
          </p>
        </section>
      </div>
    </div>
  );
};

export default DPA;
