import React from "react";
import { Link } from "react-router-dom";
import "../styles.css"; // Import the external CSS file

const Footer = () => {
  return (
    <footer className="footer-container">
      <img
        className="footer-logo"
        src="/static/media/logo.b5585893b3c5100fcca4.png"
        alt="Logo"
      ></img>
      <div className="footer-nav-links">
        <Link to="/privacy-policy" className="footer-link">
          Privacy Policy
        </Link>
        <Link to="/dpa" className="footer-link">
          Data Processing Agreement
        </Link>
        <Link to="/refund-policy" className="footer-link">
          Refund Policy
        </Link>
        <Link to="/terms-service" className="footer-link">
          Terms of Service
        </Link>
      </div>
      <div className="footer-social-icons">
        {/*<a
          href="https://discord.com"
          target="_blank"
          rel="noreferrer"
          aria-label="Discord"
        >
          <i className="fab fa-discord"></i>
        </a>
        <a
          href="https://linkedin.com"
          target="_blank"
          rel="noreferrer"
          aria-label="LinkedIn"
        >
          <i className="fab fa-linkedin"></i>
        </a>*/}
        <a
          href="https://x.com/applimatch"
          target="_blank"
          rel="noreferrer"
          aria-label="X"
        >
          <i className="fab fa-x-twitter"></i>
        </a>
        <a
          href="https://instagram.com/applimatch"
          target="_blank"
          rel="noreferrer"
          aria-label="Instagram"
        >
          <i className="fab fa-instagram"></i>
        </a>
        {/*<a
          href="https://tiktok.com"
          target="_blank"
          rel="noreferrer"
          aria-label="TikTok"
        >
          <i className="fab fa-tiktok"></i>
        </a>*/}
      </div>
      <div className="footer-copyright">
        <a href="mailto:contact@applimatch.com" className="footer-email">
          contact@applimatch.com
        </a>{" "}
        © 2024 AppliMatch. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
