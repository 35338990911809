// useWebSocket.js
import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const useWebSocket = (baseUrl, onCreditUpdate) => {
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    // Only attempt a connection if baseUrl is provided
    if (!baseUrl) return;

    const establishConnection = async () => {
      try {
        // Get a valid access token from Auth0
        const token = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          },
        });
        // Append the token as a query parameter
        const url = `${baseUrl}?token=${encodeURIComponent(token)}`;
        console.log(`Connecting to WebSocket URL: ${url}`);

        const socket = new WebSocket(url);

        socket.onopen = () => {
          console.log("WebSocket connection established");
        };

        socket.onmessage = (event) => {
          try {
            const data = JSON.parse(event.data);
            console.log("WebSocket message received:", data);
            if (
              data.type === "CREDIT_UPDATE" &&
              data.userId &&
              data.newCreditBalance !== undefined
            ) {
              onCreditUpdate(data.newCreditBalance);
            }
          } catch (err) {
            console.error("Error parsing WebSocket message:", err);
          }
        };

        socket.onerror = (error) => {
          console.error("WebSocket error:", error);
        };

        socket.onclose = () => {
          console.log("WebSocket connection closed");
        };

        return () => {
          socket.close();
        };
      } catch (err) {
        console.error("Failed to get access token for WebSocket:", err);
      }
    };

    establishConnection();
  }, [baseUrl, onCreditUpdate, getAccessTokenSilently]);
};

export default useWebSocket;
