import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const { handleLogin } = useContext(AuthContext);

  useEffect(() => {
    const doLogin = async () => {
      try {
        await handleLogin();
      } catch (error) {
        toast.error(error.message || "An error occurred. Please try again.");
      }
    };
    doLogin();
  }, [handleLogin]);
};

export default Login;
